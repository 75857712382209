<template>
	<SplitModal
		ref="modal"
		:image-width="4"
		:left-column-classes="['has-background-grey-lighterer']"
		:footer-column-classes="['has-background-white']"
	>
		<template #img>
			<div class="my-6 mx-auto pb-6" style="width: 250px">
				<div class="is-relative">
					<avatar :avatar="activeAvatar" size="xlarge" class="mx-auto" />
					<div v-for="colorGroup in colorGroups" :key="colorGroup.key" class="mx-5">
						<div class="color-name is-bold">{{ colorGroup.label }}</div>
						<div class="color-list">
							<div
								v-for="(color, index) in colorGroup.colors"
								:key="index"
								:style="{ backgroundColor: color }"
								:class="{
									active:
										color ===
										activeAvatar.colors[colorGroup.key],
								}"
								class="color-item"
								@click="useColor(color, colorGroup)"
							/>
						</div>
					</div>
				</div>
			</div>
		</template>

		<template #body>
			<p class="title is-3 mb-0">Profilbillede</p>
			<div class="mb-5 avatar-list">
				<div
					v-for="style in avatarStyles"
					:key="style"
					:class="{ 'is-active': style === activeAvatar?.style }"
					class="avatar-item"
					@click="selectStyle(style)"
				>
					<avatar
						:avatar="getAvatarWithStyle(style)"
						:highlight="style === activeAvatar?.style"
						size="large"
					/>
				</div>
			</div>
			<p v-if="achievedAvatars.length > 0" class="is-bold is-size-4 has-text-grey mb-0">Bedrifter</p>
			<div v-if="achievedAvatars.length > 0" class="mb-5 avatar-list">
				<achievable-avatar
					v-for="(av, index) in achievedAvatars"
					:key="index"
					:avatar="av"
					:highlight="av.url === activeAvatar?.url"
					:background-color="activeAvatar.colors.background"
					class="avatar-item"
					@click="clickAchievedAvatar(av)"
				/>
			</div>
		</template>

		<template #footer>
			<div class="buttons is-right">
				<button type="button" class="button is-medium" @click="hide">Luk</button>
				<button type="button" class="button is-medium is-primary" @click="save">Gem</button>
			</div>
		</template>
	</SplitModal>
</template>

<script>
import Avatar from '@/components/ui/user/Avatar.vue';
import SplitModal from '@/components/ui/modal/SplitModal';
import { mapGetters } from 'vuex';
import { featuredAchievementsDisplayedAsArray } from '@/utils/featuredAchievements';
import AchievableAvatar from '@/components/ui/user/AchievableAvatar.vue';

export default {
	name: 'AvatarModal',
	components: { AchievableAvatar, Avatar, SplitModal },
	props: {
		avatar: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			activeAvatar: null,
			achievedAvatars: [],
			colorGroups: [
				{
					key: 'background',
					label: 'Baggrund',
					colors: ['#ffd110', '#008dff', '#ff003d', '#00ce24', '#936aff'],
				},
				{
					key: 'skin',
					label: 'Hud',
					colors: ['#ffa364', '#eb947b', '#cc522f', '#a56644'],
					secondary_colors: ['skin_neck', 'facial_hair', 'undercut'],
					skin_neck_colors: ['#ed7938', '#b46852', '#90321f', '#8e5839'],
					facial_hair_colors: ['#e18156', '#c97465', '#4f1220', '#4f1220'],
					undercut_colors: ['#e18156', '#c97465', '#a54434', '#8e5839'],
				},
				{
					key: 'hair',
					label: 'Hår',
					colors: [
						'#ffbe00',
						'#9b2f45',
						'#4f1220',
						'#ff501c',
						'#dbdbdb',
						'#53f2d5',
						'#32ceea',
						'#ff50c2',
						'#e0e98f',
						'#ca6eff',
					],
				},
				{
					key: 'shirt',
					label: 'Tøj',
					colors: ['#aacfff', '#77628e', '#ffdb2c', '#832745', '#261b2a'],
				},
				{
					key: 'accessory',
					label: 'Genstand',
					colors: ['#ffd110', '#008dff', '#ff003d', '#261b2a', '#936aff'],
				},
			],
		};
	},
	computed: {
		...mapGetters('user', ['school', 'achievements', 'featuredAchievements']),
		avatarStyles() {
			return 10;
		},
		defaultAvatar() {
			const colors = {};
			this.colorGroups.forEach(group => {
				colors[group.key] = group.colors[0];
				if (group.secondary_colors) {
					group.secondary_colors.forEach(color => {
						colors[color] = group[`${color}_colors`][0];
					});
				}
			});
			return { style: 1, colors };
		},
	},
	created() {
		if (this.avatar && (this.avatar.style || this.avatar.url)) {
			this.activeAvatar = JSON.parse(JSON.stringify(this.avatar));
		} else {
			this.activeAvatar = this.defaultAvatar;
		}
	},
	methods: {
		show() {
			this.$refs.modal.show();
			this.loadAchievedAvatars();
		},
		hide() {
			this.$refs.modal.hide();
		},
		save() {
			this.$emit('save', this.activeAvatar);
			this.hide();
		},
		loadAchievedAvatars() {
			this.$store.dispatch('user/getAchievements').then(() => {
				const educations_id = this.school.school_type.id == 2 ? 1 : 10;
				this.$store.dispatch('user/getFeaturedAchievements', { educations_id }).then(() => {
					const as = this.achievements.concat(
						featuredAchievementsDisplayedAsArray(this.featuredAchievements),
					);
					this.achievedAvatars = as
						.filter(a => a.avatar)
						.map(a => {
							return {
								url: a.avatar_url,
								achievement_id: a.id,
								achieved: a.is_achieved,
								avatar_title: a.avatar_title,
							};
						});
				});
			});
		},
		getAvatarWithStyle(style) {
			const avatar = JSON.parse(JSON.stringify(this.activeAvatar));
			avatar.style = style;
			delete avatar.url;
			return avatar;
		},
		selectStyle(style) {
			this.$set(this.activeAvatar, 'style', style);
			this.$set(this.activeAvatar, 'url', null);
		},
		clickAchievedAvatar(avatar) {
			if (avatar.achieved) {
				this.$set(this.activeAvatar, 'url', avatar.url);
				this.$set(this.activeAvatar, 'style', null);
			} else {
				this.hide();
				document.dispatchEvent(new CustomEvent(`open-achievement-${avatar.achievement_id}`));
			}
		},
		useColor(color, colorGroup) {
			this.$set(this.activeAvatar.colors, colorGroup.key, color);
			const index = colorGroup.colors.findIndex(c => c === color);
			if (colorGroup.secondary_colors) {
				colorGroup.secondary_colors.forEach(color => {
					this.$set(
						this.activeAvatar.colors,
						color,
						colorGroup[`${color}_colors`][index],
					);
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
.user-profile {
	padding-bottom: 0;

	.avatar-container {
		height: 8rem;
		position: relative;
		border-top-left-radius: $radius-larger;
		border-top-right-radius: $radius-larger;

		.avatar {
			position: absolute;
			bottom: 0;
			left: 2.5rem;
		}
		.change-avatar-button {
			position: absolute;
			top: 1rem;
			right: 1rem;
			width: 3rem;
			height: 3rem;
			border-radius: 50%;
			background-color: $white;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			transition: background-color 200ms linear;
			&:hover {
				//background-color: $blue-lightest;
			}
		}
	}

	.statistics-container {
		border-bottom-left-radius: $radius-larger;
		border-bottom-right-radius: $radius-larger;
		overflow: hidden;
	}

	.avatar-list {
		display: grid;
		column-gap: 0.75rem;
		row-gap: 1.5rem;
		grid-template-columns: repeat(5, 1fr);
		.avatar-item {
			cursor: pointer;
		}
	}
	.color-name {
		text-align: center;
		font-size: 120%;
		color: $grey;
		margin-top: 1.5rem;
		margin-bottom: 0.5rem;
	}
	.color-list {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: 1rem;
		justify-items: center;
		.color-item {
			position: relative;
			width: 1.5rem;
			height: 1.5rem;
			border-radius: 50%;
			cursor: pointer;
			&:after {
				content: '';
				position: absolute;
				top: -20%;
				left: -20%;
				width: 140%;
				height: 140%;
				border-radius: 50%;
				border: 2px solid transparent;
				transition: border-color 200ms linear;
			}
			&.active:after {
				border: 2px solid $blue;
			}
		}
	}
}

:deep(.modal-left) {
	overflow-y: auto !important;
}
</style>
