import { render, staticRenderFns } from "./AvatarModal.vue?vue&type=template&id=538025e4&scoped=true&"
import script from "./AvatarModal.vue?vue&type=script&lang=js&"
export * from "./AvatarModal.vue?vue&type=script&lang=js&"
import style0 from "./AvatarModal.vue?vue&type=style&index=0&id=538025e4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "538025e4",
  null
  
)

export default component.exports